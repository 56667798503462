<template>
    <div>
        <b-modal
            id="cancel_modal"
            ref="cancel_modal"
            title="Cancel Confirmation"
            @ok="$router.push('/account/jobs')"
        >
            <p class="my-4">
                Are you sure you want to cancel?
            </p>
        </b-modal>
        <div v-if="exist === 0">
            <b-card class="mb-2">
                <b-card-text>
                    <b-row style="align-items: center;">
                        <b-col
        col
        lg="5"
        md="3"
        >
        <h4>
        Job Type
        </h4>
        </b-col>
                        <b-col
        col
        lg="3"
        md="4"
        >
                            <b-button
                                :variant="jobTypeId === 1 ? 'primary' : 'outline-primary'"
                                @click="changeJobTypeId(1)"
                            >
        Fleet Service Docket
        </b-button>
                        </b-col>
                        <b-col
        col
        lg="4"
        md="5"
        >
                            <b-button
                                :variant="jobTypeId === 2 ? 'primary' : 'outline-primary'"
                                @click="changeJobTypeId(2)"
                            >
        Vehicle Inspection Report
        </b-button>
                        </b-col>

                    </b-row>
                </b-card-text>
            </b-card>
            <b-card class="mb-2">
                <template #header>
                    <b-row style="width: 100%; align-items: center;">
                        <b-col
        col
        lg="8"
        md="7"
        >
                            <h4>Registration Number</h4>
                        </b-col>
                        <b-col
        col
        lg="4"
        md="5"
        >
                            <div class="d-flex">
                                <p class="mr-4">
        Not road registered?
        </p>
                            <b-form-checkbox
                                v-model="notRoadRegistered"
                                switch
                            />
                            </div>
                        </b-col>
                    </b-row>
                </template>
                <b-card-text class="p-3">
                    <b-row
                    v-show="!notRoadRegistered"
                    style="width: 100%;"
                    >
                        <b-col
        col
        lg="5"
        md="12"
        sm="12"
        >
                            <b-form-group label="Upload or take a photo of the license plate">
                                <b-form-file
                                    v-model="image"
                                    placeholder="Choose a file"
                                    accept="image/*"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
        col
        lg="2"
        md="12"
        sm="12"
        style="text-align: center; padding-top: 2rem; font-weight: 600;"
        >
        OR
        </b-col>
                        <b-col
        col
        lg="5"
        md="12"
        sm="12"
        >
        <b-form-group label="Enter registration manually">
                                <b-form-input
                                    v-model="regNo"
                                    :state="regNo.length<7 && regNo.length>0 ? true : false"
                                    placeholder="Enter registration number"
                                    :readonly="image"
                                />
                                <b-form-invalid-feedback id="input-live-feedback">
                                Please insert valid Registration Number
                                </b-form-invalid-feedback>
                            </b-form-group>
        </b-col>
                    </b-row>
                    <b-row v-show="notRoadRegistered">
                        <b-col
                            col
                            lg="3"
                            md="0"
                        />
                        <b-col
                            col
                            lg="6"
                            md="12"
                        >
                            <b-form-group label="Enter name of the vehicle">
                                <b-form-input
                                    v-model="vehicleName"
                                    placeholder="Enter name of the vehicle"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            col
                            lg="3"
                            md="0"
                        />
                    </b-row>
                </b-card-text>
                <template #footer>
                    <div
        class="d-flex justify-content-between"
        style="width: 100%;"
        >
                        <b-button
                            variant="outline-primary"
                            @click="triggerCancel"
                        >
        Cancel
        </b-button>
                        <b-button
                            variant="primary"
                            :disabled="!validate"
                            @click="onSubmit(0)"
                        >
        Submit
        </b-button>
                    </div>
            </template>
            </b-card>
        </div>
        <div v-else>
            <b-card class="mb-2">
                <b-card-text
                    class="p-4"
                    style="text-align: center;"
                >
                    <h4>{{ jobExistText }}</h4>
                </b-card-text>
                <template #footer>
                    <div
                        class="d-flex justify-content-between"
                        style="width: 100%;"
                    >
                        <b-button
                            variant="primary"
                            :disabled="!validate"
                            @click="onSubmit(1)"
                        >
                        Continue anyway
                        </b-button>
                        <b-button
                            variant="outline-primary"
                            @click="$router.push(`/account/jobs/show/${existingJobId}`)"
                        >
                        View existing
                        </b-button>
                    </div>
            </template>
            </b-card>
        </div>
    </div>
</template>
<script>
import EXIF from 'exif-js';
import { find } from 'geo-tz'
import momentTimezone from 'moment-timezone'
import * as imageConversion from 'image-conversion';
import {
 BCard, BCardText, BRow, BCol, BButton, BFormCheckbox, BFormGroup, BFormFile, BFormInput, BFormInvalidFeedback,
} from "bootstrap-vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
    components: {
        BCard, BCardText, BRow, BCol, BButton, BFormCheckbox, BFormGroup, BFormFile, BFormInput, BFormInvalidFeedback,
    },
    data() {
        return {
            notRoadRegistered: false,
            regNo: '',
            vehicleName: '',
            jobTypeId: 1,
            image: null,
            exist: 0,
            jobExistText: '',
            existingJobId: 0,
        }
    },
    computed: {
        validate() {
            let op = true;
            if (!this.image && (this.regNo.trim().length === 0 || this.regNo.trim().length > 6) && !this.notRoadRegistered) {
                op = false;
            }
            if (this.notRoadRegistered && this.vehicleName.trim().length === 0) {
                op = false;
            }
            return op;
        },
    },
    methods: {
        async onSubmit(addNew) {
            const formData = new FormData();
            const currentDate1 = new Date();
            const currentDate = this.$moment(currentDate1).format('YYYY-MM-DD HH:mm:ss'); // currentDate1.toISOString().slice(0, 19).replace('T', ' ');
            if (this.image) {
                EXIF.getData(this.image, function() {
                    const imgMeta = EXIF.getAllTags(this)
                    // console.log(imgMeta)
                    // formData.append('image_meta_data', imgMeta);
                });
                // console.log('this.image.exifdata ', this.image.exifdata)
                const compressed = await imageConversion.compressAccurately(this.image, 500);
                console.log(`${this.image.size}B compressed to ${compressed.size}B`);
                formData.append('image', compressed);
                // formData.append('image_meta_date', this.image.exifdata.DateTime);
                if (this.image.exifdata && this.image.exifdata.GPSLatitude && this.image.exifdata.GPSLatitude.length > 0 && this.image.exifdata.GPSLatitudeRef) {
                    let lat = parseInt(this.image.exifdata.GPSLatitude[0].numerator, 10)
                    lat += parseInt(this.image.exifdata.GPSLatitude[1].numerator, 10) / 60
                    lat += parseInt(this.image.exifdata.GPSLatitude[2].numerator / this.image.exifdata.GPSLatitude[2].denominator, 10) / 3600
                    lat = this.image.exifdata.GPSLatitudeRef === 'S' ? lat * -1 : lat
                    let long = parseInt(this.image.exifdata.GPSLongitude[0].numerator, 10)
                    long += parseInt(this.image.exifdata.GPSLongitude[1].numerator, 10) / 60
                    long += parseInt(this.image.exifdata.GPSLongitude[2].numerator / this.image.exifdata.GPSLongitude[2].denominator, 10) / 3600
                    long = this.image.exifdata.GPSLongitudeRef === 'W' ? long * -1 : long
                    // console.log('lat/long ', lat, long)
                    // const [tZone] = find(lat, long)
                    // const imgLocalTime = momentTimezone.tz(this.image.exifdata.DateTime, 'YYYY:MM:DD h:mm:ss', tZone).format()
                    // const imgUtcTime = this.$moment(imgLocalTime).utc().format()
                    /* datetime according image timezone */
                    // formData.append('image_meta_date', imgUtcTime);
                    formData.append('image_meta_date', this.image.exifdata.DateTime);
                    formData.append('image_meta_lat', this.image.exifdata.GPSLatitude);
                    formData.append('image_meta_lat_ref', this.image.exifdata.GPSLatitudeRef);
                    formData.append('image_meta_long', this.image.exifdata.GPSLongitude);
                    formData.append('image_meta_long_ref', this.image.exifdata.GPSLongitudeRef);
                } else {
                    const dateTime = this.image.exifdata ? this.image.exifdata.DateTime : currentDate
                    /* datetime according image timezone */
                    // formData.append('image_meta_date', dateTime);
                    formData.append('image_meta_date', dateTime);
                    formData.append('image_meta_lat', '');
                    formData.append('image_meta_lat_ref', '');
                    formData.append('image_meta_long', '');
                    formData.append('image_meta_long_ref', '');
                }
            } else {
                formData.append('registration_number', this.regNo);
                formData.append('image_meta_date', currentDate);
            }
            formData.append('not_road_registered', Number(this.notRoadRegistered))
            formData.append('job_type_id', this.jobTypeId)
            formData.append('add_new', addNew)
            formData.append('vehicle_name', this.notRoadRegistered ? this.vehicleName : '')
            console.log(formData)
            this.callServer(formData);
        },
        changeJobTypeId(val) {
            this.jobTypeId = val;
        },
        callServer(params) {
            this.$helper.showLoading();
            this.$axios.post('/jobs/getRegistrationNumber', params)
                    .then(resp => {
                        if ('job_exists' in resp.data) {
                            this.exist = resp.data.job_exists
                            this.jobExistText = resp.data.message
                            this.existingJobId = resp.data.job_id
                        } else {
                            this.$router.push(`/account/jobs/show/${resp.data.id}`)
                        }
                    }).catch(error => {
                        console.error(error)
                        this.$toast({
                            component: ToastificationContent,
                                props: {
                                    title: 'Oops! Something went wrong',
                                    icon: 'AlertCircleIcon',
                                    variant: 'danger',
                                },
                            });
                    }).finally(() => {
                        this.$helper.hideLoading();
                    })
        },
        triggerCancel() {
            this.$refs.cancel_modal.show();
        },
    },
}
</script>
